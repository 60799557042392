/*
 * @Author: yangliao
 * @Date: 2020-11-17 14:09:36
 * @LastEditTime: 2020-11-17 16:17:15
 * @LastEditors: yangliao
 * @Description: 反馈相关接口
 * @FilePath: /netHallOfficialAccounts/src/api/feedback.js
 */
import request from '@/utils/service';

/* 获取最新表单样式 */
export function selectMaxRelease(params) {
  return request({
    url: `/formConfigRelease/selectMaxRelease`,
    method: 'post',
    data: params
  })
}
/* 新增提交 */
export function addFeedbackHandling(params) {
  return request({
    url: `/feedbackHandling/addFeedbackHandling`,
    method: 'post',
    data: params
  })
}
/* 查询提交信息 */
export function selectFeedbackHandlingDetail(params) {
  return request({
    url: `/feedbackHandling/selectFeedbackHandlingDetail/${params}`,
    method: 'get',
  })
}
/* 取消 */
export function cancelReport(params) {
  return request({
    url: `/feedbackHandling/cancelReport/${params}`,
    method: 'get',
  })
}
/* 信息修改 */
export function updateFeedbackHandling(params) {
  return request({
    url: `/feedbackHandling/updateFeedbackHandling`,
    method: 'post',
    data: params
  })
}
